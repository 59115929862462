/* -----------------------------------
Copyright: Logical Developments 2024.
Project:   ConNote Portal
Filename:  MyUsers.js
Author:    Paul W Mulroney
Version:   0.06
Description:
The user's settings page.

History:
0.06  07-08-24 JRB   () Removed the requirement for role to not be "black" must now not be "blank"
0.05  25-11-22 DBL   (ld0011792) Go home and not back
0.04  23-08322 DBL   (ld0011361) Change setModel to use authModal and fixed bug of dropdown always showing
0.03  17-05-22 DBL   (ld0011349) Data is loaded into the store
0.02  17-05-22 JRB   (ld0011330) started fixing
0.01  04-05-22 PWM   (ld0011330) 
0.00  29-04-22 PWM   (ld0011330) Created.
----------------------------------- */

import React, { useEffect, useReducer, useState, useRef } from 'react';
import { useLocation, useNavigate as useRouterNavigate } from 'react-router-dom';
import ReactRouterPrompt from 'react-router-prompt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import useRest from '../Session/useRest';
import useNavigate from '../Navigation/useNavigate';
import useAuthenticate from '../Session/useAuthenticate';
import { compactStore, emailRegex } from '../Common/utils';
import Modal from "../Navigation/Modal";
import CardForm from '../Common/CardForm';
import { callError } from '../Configuration/Config';
import './Settings.css'

// Setup state information
class init {
  constructor () {
    this.id = {value: 0, error: null};
    this.name = {value: '', error: null};
    this.username = {value: '', error: null};
    this.email = {value: '', error: null};
    this.role = {value: 0 , error: null};        // The role of the user
    this.enabled = {value: false, error: null};
    this.resetPassword = {value: false, error: null};
    this.delete = {value: false, error: null};
    this.error = null;
  }
}

function reducer(store, action) {
  // Reducer does two things: allows us to validate fields, and saves the entry fields into the store.
  let list = store.users.map(card => ({ ...card })); // clone the entire contents of the array and
                                                     // not just the reference to each object
  let error = []; // not using a string because we want line breaks

  switch (action.name) {
    case 'add':
      // We want to vaidate the empty new user so it cannot be saved
      list.push(new init());
      // list.push(rowReducer({}, {name: 'loadStore', value: compactStore(new init())}));
      break;

    case 'delete':
      if (list[action.row].id.value === 0 ) { // deleting a new user
        list.splice(action.row, 1);
        if (list.length === 0) list.push(new init());
        break;
      }
      // ELSE we need to tell Omnis to delete the user
      // fall through

    case 'id':
    case 'name':
    case 'username':
    case 'email':
    case 'role':
    case 'enabled':
    case 'resetPassword':
      list[action.row] = rowReducer(list[action.row], action);
      break; 

    case 'loadStore':
      list = action.value.map((item, index) => { return {
          ...rowReducer(new init(), {name: 'loadStore', value: item, row: index})
      }});
      break;
    
    default:
      throw new Error(`My Users Page (${action.name}) is not supported`);
  }

  // Bubble up any errors
  list.forEach((item, index) => {
    if (item.error !== null) {
      error.push(`${item.name.value === '' ? 'New User' : 'User ' + item.name.value}: ${item.error}`);
      error.push(<br />);
    }
  });
  
  if (error.length === 0) error = null; // clear string if no errors were found
  else error.push(`Fix the issue, or ${callError}`);

  return { users: list, error: error };
}


function rowReducer(row, action) {
  // the row contains a single row from the store
  let error = null;
  let rowError = '';
  let newValue = action.value;

  // validation
  switch (action.name) {
    case 'delete':
      newValue = true; // mark the user for deletion
      break;

    case 'id':
      // no validation. This field is read-only
      break;

    case 'name':
      if (newValue === '')
        error = 'Name cannot be blank'
      else if (newValue.length > 150)
        error = 'Name cannot be longer then 150 characters'
      break;

    case 'username':
      if (newValue === '')
        error = 'Username cannot be blank'
      else if (newValue.length > 10)
        error = 'Username cannot be longer then 10 characters'
      break;

    case 'email':
      // TODO: We should probably have a warning, but we'll figure that out later
      if (newValue === '')
        error = 'email cannot be blank'
      else if (newValue.length > 1000)
        error = 'email cannot be longer then 1000 characters'
      else if (!emailRegex.test(newValue))
        error = 'email is invalid'
      break;

    case 'role':
      newValue = Number(newValue);
      if (isNaN(newValue) || newValue <= 0)
        error = 'Role cannot be blank'
      break;

    case 'enabled':
      // no validation. 
      break;

    case 'resetPassword':
      // no validation
      break;

    case 'loadStore':
      //Shortcut to save everything - call ourselves to save ourselves
      row = {
        // replace the row with a new row
        ...rowReducer({}, {name: 'id', value: newValue.id}),
        ...rowReducer({}, {name: 'name', value: newValue.name}),
        ...rowReducer({}, {name: 'username', value: newValue.username}),
        ...rowReducer({}, {name:'email', value: newValue.email}),
        ...rowReducer({}, {name:'role', value: newValue.role}),
        ...rowReducer({}, {name:'enabled', value: newValue.enabled}),
        ...rowReducer({}, {name:'resetPassword', value: newValue.resetPassword}),
        delete: {value: false, error: null}
      }
      break; // allow the errors to bubble up
    
    default:
      throw new Error(`My Users Page row (${action.name}) is not supported`);
  }

  // Bubble up any errors from the items into the row, including the actioned error
  Object.keys(row).forEach(key => {
    if (key === action.name) { // actioned error
      if (error) rowError += error + ', ';
    }
    else if (key !== 'error' && row[key].error !== null)
      rowError += row[key].error + ', ';
  });
  if (rowError === '') rowError = null; // clear string if no errors were bubbled
  else rowError = rowError.slice(0, -2); // remove the last ', '

  // return the entire row with the updated action
  if (action.name === 'loadStore')
    return { ...row, error: rowError }
  else
    return {...row, [action.name]: {value: newValue, error: error}, error: rowError};
}


function MyUsers() {
  // Allow admin users to manage users for the company
  const { loggedIn, customer, authModal } = useAuthenticate();
  const { changeType } = useNavigate();
  const [ fetchUsers, data, loadError, loading ] = useRest('GET', 'users');    // Loading the list of users
  const [ saveUser, savedOK, saveError, saving ] = useRest('POST', 'users');    // Saving the settings
  const [ store, dispatcher ] = useReducer(reducer, { users: [new init()], error: null });
  const [ error, setError ] = useState(null);                                  // Display error messages
  const [ warning, setWarning ] = useState(null);
  const navigate = useRouterNavigate();                                        // When we're done, we use this to go back to the dashboard.
  const [ row, setRow ] = useState(0);
  const [ can, setCan ] = useState({edit: false, add: false, delete: false, admin: false}) // permissions
  const [ editing, setEditing ] = useState(false);
  const [ modified, setModified ] = useState(false);                          // TODO: block navigation on true
  const [ added, setAdded ] = useState(false);
  const location = useLocation();
  const fieldRef = useRef(null);

  // on first load, set the navigation type and fetch the users
  useEffect(() => {
    changeType('default', 'Users'); // LD0011667, the second string governs the display name in the header. JDK 02-11-22
    if (loggedIn) {
      if (customer.permissions.Users.view === true) {
        fetchUsers(); // Load the users from the server
        setCan({
          edit: customer.permissions.Users.edit,
          add: customer.permissions.Users.add,
          delete: customer.permissions.Users.delete,
          admin: customer.isAdmin
        });
      }
      else
        permissionDenied('You do not have access to view the users.');
    }
  }, [loggedIn]); // eslint-disable-line

  // when the users data returns update the store
  useEffect(() => {
    if (data) {
      // Shorcut to set everything in the one place.
      dispatcher({name: 'loadStore', value: data.users});

      // Load the user if we have one in the location state
      if (location.state !== null && location.state.hasOwnProperty('id')) {
        let user = data.users.findIndex(row => row.id === location.state.id)
        if (user > 0) setRow(user);  // we were successfully passed a user
      }
    }
  }, [data, location]);

  useEffect(() => {
    if (added) {
      setRow(Math.max(store.users.length-1,0));
      setAdded(false);
      fieldRef && fieldRef.current.focus();
    }
  }, [added, store.users.length]);

  useEffect(() => {
    if (editing)
      fieldRef && fieldRef.current.focus();
  }, [editing]);

  const permissionDenied = (description) => {
    authModal({
      title: 'Permission Denied',
      description: description,
      yes: {name: 'Go Back', call: () => navigate('/')} // go home (ld0011792)
    })
  }

  // display any error or warning messages
  useEffect(() => {
    let warning = []
    if (store.error !== null) setError(store.error)
    else setError(null)

    if (loadError) warning.push(loadError)
    if (saveError) warning.push(saveError)
    if (warning.length === 0) warning = null
    setWarning(warning);
  }, [store, loadError, saveError]);

  // saveOK returned from the server, alert for any errors
  useEffect(() => {
    if (savedOK) {    
      if (savedOK.success) navigate('/')
      else setError(savedOK.message)     // Didn't save, something went wrong at the server, report that!
    } 
  }, [savedOK, navigate]);

  // User clicked Save
  const handleSave = (enabled) => {
    if (enabled) {
      setModified(false)
      saveUser(compactStore(store))
    }
  }

  let controls = {
    // Saving var allows us to make the save button say "saving" while it is being saved on the server. Disable button while saving.
     next: { call: handleSave, name: saving ? 'Saving' : 'Save', enable: (loading||saving||error) ? false : true },
     // Cancel takes us back to the dashboard.
     previous: { call: () => navigate('/'), name: 'Cancel', enable: true }
  };
  
  // Shortcut to define everything we need for each entry field.
  const handleInput = (inputName, classes) => {
    if (row >= 0 && row < store.users.length)
      return ({
        id: inputName,
        name: inputName,
        value: store.users[row][inputName].value,
        className: store.users[row][inputName].error ? classes + ' error-outline' : classes,
        onChange: (e) => {dispatcher({name: e.target.name, value: e.target.value, row: row}); setModified(true)},
        onBlur: (e) => dispatcher({name: e.target.name, value: e.target.value, row: row}),
        disabled: editing ? false : true
      });
    else
      setRow(0)
  };
  

  return (
    <CardForm title='Users' controls={controls} className="margin-block-start" error={error} warning={warning}>
      <div className='flex_container'>
        <div className="stack" style={{ minInlineSize: "15ch" }}>
          <p className='h3 table-heading' style={{ textAlign: "center" }}>Name</p>
          <div className='stack my-users'>
            { loading ? <p className="h1">Loading...</p> :
              store.users.reduce((list, thisUser, index) => {
                if (thisUser.delete.value === false) 
                  return [
                    ...list,
                    <button type='button'
                      key={`user${index}`}
                      className={index === row ? 'checked btn' : 'not clicked btn'}
                      onClick={() => {
                        if (index !== row) {
                          setRow(index);
                          setEditing(false); // disable editing when the user is changed
                        }
                      }}
                    >
                      {thisUser.name.value === '' ? 'New User' : thisUser.name.value}
                    </button>
                  ]
                else return list // don't display a deleted user
              }, [])
            }
          </div>
        </div>

        <div className='box stack user-form'>
          <div className="cluster cluster-outer">
            <button title={can.edit ? undefined : 'no access' }
              className='btn__link margin-inline-start-auto'
              type='button'
              onClick={() => setEditing(!editing)}
              disabled={loading||saving||!can.edit}
            >
              {editing ? 'Lock User' : 'Edit User'}
            </button>
          </div>
          <div className='cluster cluster-outer'>
            <label htmlFor='name'>Full Name: </label>
            <input type='text' ref={fieldRef} required {...handleInput('name')} />
          </div>
          <div className='cluster cluster-outer'>
            <label htmlFor='username'>Username: </label>
            <input type='text' required {...handleInput('username')} />
          </div>
          <div className='cluster cluster-outer'>
            <label htmlFor='email'>Email: </label>
            <input type='email' required {...handleInput('email')} />
          </div>
          <div className='cluster cluster-outer'>
            <label htmlFor='role'>Role: </label>
            <select required {...handleInput('role')} disabled={!(can.admin && editing)}>
              <option hidden disabled value='0'></option>
              {data && data.roles.map((role, index) => (
                <option key={`role${index}`} value={role.ugSEQ}>{role.ugName}</option>
              ))}
            </select>
          </div>
          <div className='cluster cluster-outer'>
            <label htmlFor='enabled'>Enabled: </label>
            <input type="checkbox"
              id='enabled'
              checked={store.users[row] && store.users[row].enabled.value}
              disabled={editing ? false : true}
              onChange={(e) => {
                dispatcher({name: e.target.id, value: e.target.checked, row: row})
                setModified(true)
              }}
            />
          </div>
          <div className='cluster cluster-outer'>
            <label htmlFor='resetPassword'>Reset Password: </label>
            <input type='checkbox'
              id='resetPassword'
              checked={store.users[row] && store.users[row].resetPassword.value}
              disabled={editing ? false : true}
              onChange={(e) => {
                dispatcher({name: e.target.id, value: e.target.checked, row: row})
                setModified(true)
              }}
            />
          </div>
        </div>
      </div>

      <div className='cluster' style={{ marginInlineStart: "1.125rem" }}>
        <button title={can.add ? 'Create new user' : 'Create new user - no access' }
          className="btn btn__add"
          type='button'
          onClick={() => {
            dispatcher({name: 'add', value: ''});
            setAdded(true);
            setEditing(true);
          }}
          disabled={loading||saving||!can.add}
        >
          <FontAwesomeIcon icon={faPlus} />
        </button>
        <button title={can.delete ?`Delete ${store.users[row] && store.users[row].name.value}` : 'Delete - no access'}
          className="btn btn__delete"
          type='button'
          onClick={() => {
            if (row > 0) setRow(row - 1);
            dispatcher({name: 'delete', row: row, value: ''});
            setModified(true);
          }}
          disabled={loading||saving||!can.delete}
        >
          <FontAwesomeIcon icon={faMinus} />
        </button>
      </div>

      <ReactRouterPrompt when={loggedIn && modified}>
        {({ isActive, onConfirm, onCancel }) => (
          <>{ isActive &&
            <Modal
              title={'Confirm Cancel'}
              description={'You have unsaved changes, do you want to leave?'}
              yes={{call:  onConfirm}}
              no={{call: onCancel}}
            />
          }</>
        )}
      </ReactRouterPrompt>
    </CardForm>
  );
}

export default MyUsers