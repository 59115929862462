/* -----------------------------------
Copyright: Logical Developments 2025.
Project:   ConNote Portal
Filename:  PageConsignment.js
Author:    Jordan R. Bock
Version:   0.05
Description:
The Consignment download page for the Drop Freight workflow.

History:
0.06  03-04-25 JRB  LD0013312 Fixed delay in email validation.
0.05  02-04-25 JRB  LD0013312 Added handling for loading a customer default email and being able to change it before initiating the download.
0.04  11-10-124 JRB LD0013078 Replaced ElectronicConsignment function with ElectronicConsignment.js 
0.03  19-09-24 JRB  LD0013019 Defined setError to prevent typescript errors. 
0.02  11-03-24 JRB  LD0012666 Inform user to print and bring along connote when a eConNote has been booked.
0.01	05-02-24 JRB   Created.
----------------------------------- */

import React, { useEffect, useState } from 'react';
import { useNavigate as useRouterNavigate } from 'react-router-dom'
// import useNavigate from '../Navigation/useNavigate';
import CardForm from '../Common/CardForm';
import ElectronicConsignment from '../Common/ElectronicConsignment';
import { PrintLabel } from '../Common/PageConsignmentLabel';
import useRest from '../Session/useRest';
import { compactStore } from '../Common/utils';

class init {

}

function reducer(store, action) {
  let error = null;
  let newValue = action.value;

  switch(action.name) {
     case 'loadStore':
      return {
      };

    default: {
      throw new Error(`Schedule Pickup Reducer (${action.name}) is not supported`);
    }
  }
    
  // return { [action.name]: { value: newValue, error: error } };
}

function PageConsignmentDownload({store, dispatcher, controls}) {
  const consignNumber = (store.conNumber && store.conNumber.value) ? store.conNumber.value : null;
  const readyDate = (store._readyAtDate && store._readyAtDate.value) ? store._readyAtDate.value : null;
  // Hooks, custom or otherwise.
  // const { authModal } = useAuthenticate()
  // const { navState, changeState } = useNavigate()
  const navigate = useRouterNavigate();

  // const display = (store.conType.value === 'electronic') ? 2 : 1;
  // stateful constants
  const [display, setDisplay] = useState((store.conType.value === 'electronic') ? 2 : 1);
  const [customCtrls, setCustCtrls] = useState({});
  const [error, setError] = useState(null);
  // REST definition
  const [ bookConnote, connoteData, connoteError ] = useRest('POST', 'directdropfreight/validate');
  const modal = {
      title: 'Drop Freight In booked',
      description: [
        `Your consignment number is ${consignNumber}`,
        <br />,
        `Your drop freight has been booked for ${readyDate}`,
        <br />,
        `Please print this connote and bring in with your freight.`
      ],
      yes: {name: 'Return to Dashboard', call: () => navigate('/')}
    }
  
  useEffect(() => {
    if (connoteData || connoteError) {
      if (connoteData.success === true)
        console.log(display);
        if (display === 1) {
          dispatcher({page: 'dfConsignment', name: 'conType', value: 'manual'})
        } else if (display === 2) {
          dispatcher({page: 'dfConsignment', name: 'conType', value: 'electronic'}) 
        }
      else if (connoteData.success === false)
        setError(connoteData.message)
      else if (connoteError)
        setError(connoteError)
    }
  },[connoteData, connoteError])

  // There is no interaction on the page so do not need useEffects, re-rendering everything is okay.

  const nextCall = (enabled) => {
    // time may have lapsed, validate the scheduled time
    let today = new Date().setHours(0,0,0,0)
    let booked = new Date(store.readyAt.value).setHours(0,0,0,0)
    if ( booked < today)
      setError('Scheduled drop in date cannot be in the past');
    else if (enabled)
      bookConnote(compactStore(store));
  }

  useEffect (() => {
    switch (store.conType.value) {
      case 'preManual':
        setCustCtrls({ ...controls,
          next: {
            ...controls.next,
            call: nextCall,
            name: 'Submit',
            enable: true,
            hidden:(store.conType.value==='preManual' ? false : true)
          }
        });
        setDisplay(1);
        break;

      case 'preElectronic':
        setCustCtrls({ ...controls,
          next: {
            ...controls.next,
            call: nextCall,
            name: 'Submit',
            enable: true,
            hidden:(store.conType.value==='preElectronic' ? false : true)
          }
        });
        setDisplay(2);

        break;
      case 'electronic':
        setCustCtrls({});
        setDisplay(3);
        break;
      case 'manual':
        setCustCtrls({});
        setDisplay(4);
        break;
      default:
        // setCustCtrls({ ...controls,
        //   next: {
        //     ...controls.next,
        //     hidden:(false )
        //   }
        // });
        setDisplay(0);
    }
  }, [store, error]) // eslint-disable-line

  useEffect (() => {
    setError(store.ExtraEmail.error); //When we change the Consignment number and are manual, set error status to whatever the email's state is
  },[store.ExtraEmail.error]); // eslint-disable-line

  return (
    <CardForm title="Booked Dropfreight" className='workflow'
      controls={customCtrls} error={error ? error : ''}
    >
      {display === 1 && <ManualConsignment store={store} dispatcher={dispatcher}/>}
      {display === 2 && <PreElectronicConsignment store={store} dispatcher={dispatcher}/>}
      {display === 3 && <ElectronicConsignment store={store} setError={setError} modal={modal} navigate={navigate}/>}
      {display === 4 && <PrintLabel consignNumber={store.conNumber.value} modal={modal} extraEmail={store.ExtraEmail.value}/>}
    </CardForm>
  )
}

// function ManualConsignment({store}) {
//   return (
//     <section className='accent'>
//       <div className='stack'>
//         {/* <p className='h3'>Manual Consignment</p> */}
//         <p>Thank you for using the Bishops Transport Customer Portal.</p>
//         <p>Your drop in has been booked for {store._readyAtDate.value}.</p>
//       </div>
//     </section>
//   )
// }

function ManualConsignment({store, dispatcher}) {
  const handleChange = e => {
    dispatcher({page: 'dfConsignment', name: e.target.name, value: e.target.value})
  }
  return (
    <section className='accent'>
      <div className='stack'>
        <p className='h3'>Manual Consignment</p>
        <p>Send a copy of the label to:</p>
        <input name='ExtraEmail' type='text' onChange={handleChange} onInput={handleChange} value={store.ExtraEmail.value}/>
      </div>
    </section>
  )
}

function PreElectronicConsignment({store, dispatcher}) {
  const handleChange = e => {
    dispatcher({page: 'dfConsignment', name: e.target.name, value: e.target.value})
  }
  return (
    <section className='accent'>
      <div className='stack'>
        <p className='h3'>Electronic Consignment</p>
        <p>Send a copy of the consignment and label to:</p>
        <input name='ExtraEmail' type='text' onChange={handleChange} onInput={handleChange} value={store.ExtraEmail.value} optional/>
      </div>
    </section>
  )
}

export default PageConsignmentDownload;
export { init, reducer };