/* -----------------------------------
Copyright: Logical Developments 2025.
Project:   ConNote Portal
Filename:  ConvertQuote.js
Author:    Dean B. Leggo
Version:   0.08
Description:
Convert a Quote to a Pickup.

History:
0.08  02-04-25 JRB   LD0013312 Added customer default extra email
0.07  16-10-23 JRB   (ld0012284) Load open and close schedule times from customer default.
0.06  12-05-23 DBL   (ld0012035) Changed to support Multiple destinations
0.05  25-11-22 DBL   (ld0011792) Go home and not back
0.04  21-11-22 JRB   (ld0011770) Changed order of store loading.
0.03  18-10-22 DBL   Moved loading the quote into NewPickup PageDetails
0.02  13-10-22 JRB   Added Review to load into when we get data back.
0.01  23-08-22 DBL   Change setModel to use authModal
0.00	20-04-22 DBL   Created.
----------------------------------- */

import React, { useState, useEffect, useReducer } from "react";
import { useLocation, useNavigate as useRouterNavigate } from 'react-router-dom';
import useNavigate from '../Navigation/useNavigate';
import useAuthenticate from '../Session/useAuthenticate';
import useRest from "../Session/useRest";
import { getStore } from '../Session/Storage';
import { mergeDestinations } from "../Common/utils";
import PageAccounts, * as Accounts from "../NewPickup/PageAccounts";             // from NewPickup
import PageDetails, * as Details from "../NewPickup/PageDetails";                // from NewPickup
import PageDG, * as DG from "../NewPickup/PageDG";                               // from NewPickup
import PageSchedule, * as Schedule from "../NewPickup/PageSchedule";             // from NewPickup
import PageReview, * as Review from "../NewPickup/PageReview";                   // from NewPickup
import PageConsignment, * as Consignment from '../NewPickup/PageConsignment';    // from NewPickup
import PageFreight, * as Freight from "./PageFreight";
import PageInstructions, * as Instructions from "./PageInstructions";
import { freightCardValidation as validation } from "../Configuration/Config";

const menu = [{name: 'Accounts', click: true},
              {name: 'Details', click: false},
              {name: 'Check Freight', click: false},
              {name: 'Dangerous Goods', click: false},
              {name: 'Schedule Pickup', click: false},
              {name: 'Special Instructions', click: false},
              {name: 'Review Pickup', click: false},
              {name: 'Create Consignment', click: false}];
              
const getOptions = () => {
  let options = getStore('options');
  return (options !== null && Object.keys(options).length) ? options : null;
}

class init {
  constructor(options) {
    let accounts = new Accounts.init();
    let details = new Details.init();
    let freight = new Freight.init();
    let dg = new DG.init();
    let schedule = new Schedule.init(options ? options.customerDefaults.defaultOpen : null, options ? options.customerDefaults.defaultClose : null); // Schedule is the only field not enterable in a quote so only load this when coming from a quote.
    let instructions = new Instructions.init();
    let review = new Review.init();
    let consignment = new Consignment.init(options ? options.customerDefaults.defaultExtraEmail : null);
    return {...accounts, ...details, ...freight, ...dg, ...schedule, ...instructions, ...review, ...consignment};
  }
}

function reducer(store, action) {
  //console.log('Convert Quote Reducer: store, action'); console.log(store); console.log(action);

  // pass down the entry to update and the action, adding the updated entry into the store
  switch (action.page) {
    case 'Accounts':
      return {...store, ...Accounts.reducer(store, action)};
    case 'Details':
      return {...store, ...Details.reducer(store, action)};
    case 'Freight':
      return {...store, ...Freight.reducer(store, action)};
    case 'DG':
      return {...store, ...DG.reducer(store, action)};
    case 'Schedule':
      return {...store, ...Schedule.reducer(store, action)};
    case 'Instructions':
      return {...store, ...Instructions.reducer(store, action)};
    case 'Review':
      return {...store, ...Review.reducer(store, action)};
    case 'Consignment':
      return {...store, ...Consignment.reducer(store, action)};
    // case 'loadStore': you cannot have a draft of a conversion so no need to load the store
    case 'reset':
      return new init(getOptions())
    
    default:
      throw new Error(`Convert Quote Page (${action.page}) is not supported`);
  }
}

function ConvertQuote() {
  const { navState, changeType, changeState } = useNavigate();
  const navigate = useRouterNavigate();
  const location = useLocation();
  const { loggedIn, customer, authModal } = useAuthenticate();
  const [ store, dispatcher ] = useReducer(reducer, new init(getOptions()));
  const [ fetchOptions, options, optionsError ] = useRest('GET', 'quoteConvert');
  const [ myOptions, setMyOptions ] = useState({});

  // useEffect(() => { console.log('Store:'); console.log(store) }, [store]); // debugging the Store

  // on first load, set the navigation type and initial state
  useEffect(() => {
    if (loggedIn) {
      changeType('stepper', { stepArray: menu, stepCurrent: 1 });
      if (customer.permissions.Quotations.edit === true && customer.permissions.Pickups.add === true)
        if (location.state && location.state.hasOwnProperty('quote'))
          fetchOptions({quote: location.state.quote.quoteNumber});
        else
          permissionDenied('You do not have a quotation to convert');
      else
        permissionDenied('You do not have access to convert a quotation to a pickup');
    }
  }, [loggedIn]); // eslint-disable-line

  // when the options arrive, load the store
  useEffect(() => {
    if (options) {
      const newOptions = {
        ...options,
        suburbs: mergeDestinations(options.suburbs, options.depots)
      }

      setMyOptions(newOptions)
      dispatcher({page: 'Instructions', name: 'loadStore', value: location.state.quote});
      dispatcher({page: 'Details', name: 'loadAddresses', value: {
        sender: newOptions.suburbs.find(s => s.value === location.state.quote.sender),
        receiver: newOptions.suburbs.find(s => s.value === location.state.quote.receiver),
        depots: newOptions.depots
      }})
      dispatcher({page: 'Details', name: 'loadQuote', value: location.state.quote, options: newOptions})
      dispatcher({page: 'Freight', name: 'loadStore', value: location.state.quote, validation});
      dispatcher({page: 'DG', name: 'loadStore', value: location.state.quote});
      dispatcher({page: 'Review', name: 'loadStore', value: location.state.quote});
      dispatcher({page: 'Consignment', name: 'ExtraEmail', value: getOptions().customerDefaults.defaultExtraEmail});
    }
  }, [options, location.state])

  // Issue loading the options, bail out. Most likely permission denied due to already being converted
  useEffect(() => {if (optionsError) permissionDenied(optionsError)}, [optionsError]) // eslint-disable-line

  const permissionDenied = (description) => {
    authModal({
      title: 'Permission Denied',
      description: description,
      yes: {name: 'Go Back', call: () => navigate('/')} // go home (ld0011792)
    });
  };

  // default previous click
  const previousClick = (enabled) => {
    if (enabled) {
      const goto = navState.stepCurrent - 1;
      const newMenu = navState.stepArray.map((item, index) =>
        index + 1 <= goto ? { ...item, click: true } : { ...item, click: false }
      );
      changeState({ ...navState, stepCurrent: goto, stepArray: newMenu });
    }
  };

  // default next click and enable the next menu item
  const nextClick = (enabled) => {
    if (enabled) {
      const goto = navState.stepCurrent + 1;
      const newMenu = navState.stepArray.map((item, index) =>
        index + 1 <= goto ? { ...item, click: true } : { ...item, click: false }
      );
      changeState({ ...navState, stepCurrent: goto, stepArray: newMenu });
    }
  }

  // send all controls to each page and they can decide what to use
  let controls = {
    next: {call: nextClick, enable: false},
    previous: {call: previousClick, enable: true}
  };


  // Switch the card forms
  if (navState)
    switch (navState.stepCurrent) {
      case 1:
        return (
          <div className='hideOverflow-x'>
            <PageAccounts store={store} dispatcher={dispatcher} controls={controls} options={myOptions} />
          </div>
        );
      case 2:
        return (
          <div className='hideOverflow-x'>
            <PageDetails store={store} dispatcher={dispatcher} controls={controls} options={myOptions} />
          </div>
        );
      case 3:
        return (
          <div className='hideOverflow-x'>
            <PageFreight store={store} dispatcher={dispatcher} controls={controls} options={myOptions} validation={validation} />
          </div>
        );
      case 4:
        return (
          <div className='hideOverflow-x'>
            <PageDG store={store} dispatcher={dispatcher} controls={controls} options={myOptions} />
          </div>
        );
      case 5:
        return (
          <div className='hideOverflow-x'>
            <PageSchedule store={store} dispatcher={dispatcher} controls={controls} options={myOptions} />
          </div>
        );
      case 6:
        return (
          <div className='hideOverflow-x'>
            <PageInstructions store={store} dispatcher={dispatcher} controls={controls} options={myOptions} />
          </div>
        );
      case 7:
        return (
          <div className='hideOverflow-x'>
            <PageReview store={store} dispatcher={dispatcher} controls={controls} options={myOptions} />
          </div>
        );
      case 8:
        return (
          <div className='hideOverflow-x'>
            <PageConsignment store={store} dispatcher={dispatcher} controls={controls} options={myOptions} />
          </div>
        );
      default: return null;
    }
  else
    return null;
}

export default ConvertQuote;
