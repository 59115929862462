/* -----------------------------------
Copyright: Logical Developments 2025.
Project:   ConNote Portal
Filename:  PageReview.js
Author:    Jordan R. Bock
Version:   0.04
Description:
The Review Drop Freight page for the Drop Freight workflow.

History:
0.05  02-04-25 JRB  LD0013312 Do not handle data update here. Do it in the download step after confirming the email.
0.04  17-07-24 JRB (ld0012843) Added Print Label Button when a consignment is linked.
0.03  05-06-24 JRB (ld0012770) Added Quote Number to displayed fields. Fixed Edit button destinations
0.02  08-03-24 JRB (ld0012663) Do not set deliveryRequired here. It is handled in pageServices.
0.01	24-01-24 JRB   Created.
----------------------------------- */

import React, { useEffect, useState } from 'react';
import CardForm from '../Common/CardForm';
import { legal } from '../Configuration/Config';
// import { useLocation } from 'react-router-dom';
import useNavigate from '../Navigation/useNavigate';
import useAuthenticate from '../Session/useAuthenticate';
// import { displayPkpMsg } from '../Common/utils';
import { FreightSummary } from '../Common/FreightCards'
// import { compactStore } from '../Common/utils';
// import useRest from '../Session/useRest';
import { useNavigate as useRouterNavigate } from 'react-router-dom';

class init {
  constructor () {
    // this.deliveryRequired = {value: 1, error: null};
    this.scheduleDay = {value: null, error: null}; // Date object
  }
}

// Receives the store property from the NewQuote store and the action
function reducer(store, action) {
  let newValue = action.value;
  switch (action.name) {
    case 'scheduleDay':
      return {
        scheduleDay:  {value: newValue ? new Date(newValue) : null, error: null}
      }

    case 'dateBooked':
      if (newValue) {
        const bookedDate = new Date(newValue)
        return {
          bookedAt: {
            value: bookedDate.toISOString(),
            error: null
          }
        }
      } else {
        return {
          bookedAt: {
            value: null,
            error: null
          }
        }
      }

    case 'loadStore':
      // if (newValue.pickupRequired < 0 || newValue.pickupRequired > 3) newValue.pickupRequired = 0;
      if (newValue.deliveryRequired < 0 || newValue.deliveryRequired > 3) newValue.deliveryRequired = 0;
      return {
        ...reducer(store, {name: 'scheduleDay', value: newValue.scheduleDay}),
        ...reducer(store, {name: 'dateBooked', value: newValue.dateBooked}),
        deliveryRequired: {value: newValue.deliveryRequired, error: null}

        // bookedAt: {value: newValue.dateBooked, error: null}
      }

    default:
      throw new Error(`Services Reducer (${action.name}) is not supported`);
  };
}

function PageReview({store, controls}) {
  // const location = useLocation();
  const { loggedIn, customer } = useAuthenticate();
  const { navState, changeState } = useNavigate();
  const [ error, setError ] = useState(null)
  // const [ bookConnote, connoteData, connoteError ] = useRest('POST', 'directdropfreight/validate');
  const navigate = useRouterNavigate();

  // There is no interaction on the page so do not need useEffects, re-rendering everything is okay.

  const nextCall = (enabled) => {
  //   // time may have lapsed, validate the scheduled time
  //   let today = new Date().setHours(0,0,0,0)
  //   let booked = new Date(store.readyAt.value).setHours(0,0,0,0)
  //   if ( booked < today)
  //     setError('Scheduled drop in date cannot be in the past');
  //   else if (enabled)
  //     bookConnote(compactStore(store));
   controls.next.call(enabled);
  }

  const doLabel = (enabled) => {
    if (enabled) {
      navigate('/consignments/generatelabel', {state: {conNumber: store.conNumber.value}})
    }
  }

  // useEffect(() => {
  //   if (connoteData || connoteError) {
  //     if (connoteData.success === true)
  //       controls.next.call(true);
  //     else if (connoteData.success === false)
  //       setError(connoteData.message)
  //     else if (connoteError)
  //       setError(connoteError)
  //   }
  // },[connoteData, connoteError])

  // go directly to the page number passed
  const gotoDetails = (pageNum) => {
    const newMenu = navState.stepArray.map((item, index) => ({ ...item, click: index + 1 <= pageNum })); 
    changeState({ ...navState, stepCurrent: pageNum, stepArray: newMenu});
  }

  const sender = store.sender;
  const receiver = store.receiver;

  const senderAddrLine1 = sender.streetAddress.value.trim();
  const senderAddrLine2 = `${sender.suburb.value}, ${sender.state.value.trim()} ${sender.postCode.value.trim()}`.trim();

  const receiverAddrLine1 = receiver.streetAddress.value.trim();
  const receiverAddrLine2 = `${receiver.suburb.value}, ${receiver.state.value.trim()} ${receiver.postCode.value.trim()}`.trim();
  
  const canEdit = store.status.value === 2;
  // const pickupMessage = displayPkpMsg(store.readyAt.value, store.closedAfter.value, null, store.bookedAt.value ? store.bookedAt.value : undefined); // Use undefined so we don't pass anything in if not booked yet.
 
  return (
    <CardForm
      title='Review Your Drop In'
      controls={canEdit
        ? { ...controls,
            next: {
              ...controls.next,
              name: 'Book Drop In',
              call: nextCall,
              enable: error === null
            }
          }
        : {
          next: {
            ...controls.next,
            name: 'Print Label',
            call: doLabel,
            enable: true
          }}
      }
      className="workflow"
      error={error}
    >
      <div className='summary-row'>
        <div className='stack'>
          {canEdit && <div style={{alignSelf: "flex-end"}}>
            <button className='btn__link' onClick={() => gotoDetails(3)}>Edit</button>
          </div>}
          <div className="box" style={{blockSize: "100%"}}>
            <h3 style={{marginBlockEnd: "1rem"}}>Location details</h3> {/* Match spacing with Account */}
            <p className='h3'>Sender</p>
            <p>{`${store.sender.company.value}`}</p>
            <p>{`${senderAddrLine1}`}<br/>{`${senderAddrLine2}`}</p>
            <br/>
            <p className='h3'>Receiver</p>
            <p>{`${store.receiver.company.value}`}</p>
            <p>{`${receiverAddrLine1}`}<br/>{`${receiverAddrLine2}`}</p>
          </div>
        </div>

        <div className='stack'>
          {canEdit && <div style={{alignSelf: "flex-end"}}>
            <button className='btn__link' onClick={() => gotoDetails(3)}>Edit</button>
          </div>}
          <div className="box" style={{blockSize: "100%"}}>
            <h3>Account</h3>
            <p>{loggedIn && customer.company}</p>
            <br/>
            {store.conNumber.value !== ''
              ? <><p className='h3'>Consignment Number</p><p>{store.conNumber.value}</p><br/></>
              : undefined}
            
            {store.quoteNumber.value !== ''
              ? <><p className='h3'>Quote Number</p><p>{store.quoteNumber.value}</p><br/></>
              : undefined}

            {(store.deliveryRequired.value === 1)
              ? <><p className='h3'>Collected from depot</p><br/></>
              : undefined}

            {(store.deliveryRequired.value >= 2)
              ? <><p className='h3'>Delivery Required</p><br/></>
              : undefined}

            {(store.deliveryRequired.value === 3)
              ? <><p className='h3'>Forklift Required</p><br/></>
              : undefined}
            
            <p style={{marginBlockStart: "auto"}} className='h3' >Scheduled Drop In Date</p>
            <p>{store._readyAtDate.value !== null ? store._readyAtDate.value : ''}</p>

            {/* {pickupMessage.scheduleMsg.value
              ? <><p className='h3'>Please note</p><p>{pickupMessage.scheduleMsg.value}</p></>
              : pickupMessage.scheduleMsg.error
                ? <><p className='h3'>Please note</p><p>{pickupMessage.scheduleMsg.error}</p></>
                : undefined
            } */}
          </div>
        </div>
      </div>
      
      <FreightSummary store={store.freightList} title={<h3>Freight Details</h3>} edit={canEdit ? () => gotoDetails(4) : null} />
      {store.instructions.value ? <h3 className='accent'>Special Instructions</h3> : ''}
      <p>{store.instructions.value}</p>
      <section className='accent stack'>
        {legal.body}{store.status.value !== 2 ? legal.consignmentComplete : '' }
      </section>
    </CardForm>
  )
}

export default PageReview;
export { init, reducer };