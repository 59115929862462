/* -----------------------------------
Copyright: Logical Developments 2025.
Project:   ConNote Portal
Filename:  PageInstructions.js
Author:    John D. Kohl
Version:   0.08
Description:
The Special Instructions page for the New Pickup workflow.

History:
0.08  07-03-25 JRB   LD0013247 When loading store without status, set status to 0 instead of empty string to match the constructor.
0.07  29-08-24 JRB   LD0012951 Added validation import, Dispatch to freight list to update our freight IDs. Removed unnecessary useEffect() dependancies.
0.06  28-08-23 JDK   LD0012210 Initialise new pickups with customer specific defaults.
0.05  18-01-23 JDK   LD0011885 Added path based navigation, so that if we're creating a new pickup, the pathname changes to 'existing' once the record has been committed.
0.04  16-01-23 JDK   LD0011887 Removed '_oldStore'. Refreshing the browser updated this value, rendering it pointless.
0.03  21-11-22 DBL   LD0011774 Both POSTs shoud dispatch the scheuleDay
0.02  08-11-22 DBL   LD0011685 Added scheduleDay
0.01	23-03-22 JDK   Copied from New Quote workflow.
----------------------------------- */

import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CardForm from '../Common/CardForm';
import { compactStore } from '../Common/utils';
import useRest from '../Session/useRest';
import { freightCardValidation as validation } from "../Configuration/Config";

class init {
  constructor (defaultInstructions) {
    // this._oldStore = {value: '', error: null};
    this.instructions = {value: defaultInstructions ? defaultInstructions : '', error: null};
    this.quoteNumber = {value: '', error: null};
    this.pickupNumber = {value: '', error: null};
    this.status = {value: 0, error: null};
  }
}

function reducer(store, action) {
  //console.log('Instructions Reducer: store, action'); console.log(store); console.log(action);
  let error = null;
  let newValue = action.value;

  // validate the action
  switch (action.name) {
    case 'instructions':
      if (newValue.length > 850) // 150 characters are reserved from the schedule
        error = "Special instructions are too long"
      break;

    case 'status':
      if (!newValue) newValue = 0
      break; //No validation required

    case 'quoteNumber':
    case 'pickupNumber':
    // case '_oldStore': // used to compare pickups to check if an edit has occurred or not
      break; //No validation required

    case 'loadStore':
      const quote = newValue.hasOwnProperty('quoteNumber')
        ? reducer(store, {name: 'quoteNumber', value: newValue.quoteNumber})
        : { quoteNumber: {value: '', error: null} };

      const pickup = newValue.hasOwnProperty('pickupNumber')
        ? reducer(store, {name: 'pickupNumber', value: newValue.pickupNumber})
        : { pickupNumber: {value: '', error: null} };

      const status = newValue.hasOwnProperty('status')
        ? reducer(store, {name: 'status', value: newValue.status})
        : { status: {value: 0, error: null} }; // This should have been 0 all along to match the init when no value is available

      return {
        // ...reducer(store, {name: '_oldStore', value: newValue}),
        ...reducer(store, {name: 'instructions', value: newValue.instructions}),
        ...quote,
        ...pickup,
        ...status
      }

    default:
      throw new Error(`Instructions Reducer (${action.name}) is not supported`);
  };

  return { [action.name]: {value: newValue, error: error} };
}

function PageInstructions({store, dispatcher, controls}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [ create, createSuccess, createError, createLoading ] = useRest('POST', 'pickups/new');
  const [ update, updateSuccess, updateError, updateLoading ] = useRest('POST', 'pickups/edit');
  const [ error, setError ] = useState(null);

  // when the pickup is saved, move to the next screen
  useEffect(() => {
    if (createSuccess || updateSuccess) {
      const data = createSuccess ? createSuccess : updateSuccess;
      if (data.success) {
        dispatcher({page: 'Instructions', name: 'pickupNumber', value: data.pickupNumber});
        dispatcher({page: 'Review', name: 'scheduleDay', value: data.scheduleDay});
        dispatcher({page: 'Freight', name: 'freightList', value: { name: 'updateIDs', value: data.freightItemIDs, validation: validation }}); // Update the ids of our items
        controls.next.call(true);
      } else if (!data.success && data.message) {
        setError(data.message);
      }
    }
  }, [createSuccess, updateSuccess, dispatcher, controls]);


  // Display any errors on the screen
  useEffect(() => {
    let error = [];
    store.instructions.error && error.push(store.instructions.error);
    createError && error.push(createError);
    updateError && error.push(updateError);
    error.length ? setError(error) : setError(null);
  }, [createError, updateError, store])


  // When next is clicked save the pickup
  const save = (enabled) => {
    if (enabled) {
      if (store.pickupNumber.value) {
        update(compactStore(store)); // edit;
      } else {
        // const newStore = compactStore(store);
        // dispatcher({page: 'Instructions', name: '_oldStore', value: newStore}) // this way if we go back and edit things we have a reference to determine update changes
        create(compactStore(store)); // new
      }
      if(location.pathname === '/pickup/new') navigate("/pickup/existing");
    }
  };


  const handleInput = (inputName, classes) => {
    return ({
      name: inputName,
      value: store[inputName].value,
      className: store[inputName].error ? classes + ' error-outline' : classes,
      onChange: (e) => dispatcher({page: 'Instructions', name: e.target.name, value: e.target.value})
    });
  };

  return (
    <CardForm title='Special Instructions' className="workflow" error={error}
      controls={{
        ...controls,
        next: {
          call: save,
          enable: !error && !createLoading && !updateLoading,
          name: createLoading || updateLoading ? 'Saving' : 'Next'
        }
      }}
    >
      <div className="stack">
        <label htmlFor="instructions">Are there any special instructions?</label>
        <textarea {...handleInput('instructions')} />
      </div>
    </CardForm>
  )
}

export default PageInstructions;
export { init, reducer };