/* -----------------------------------
Copyright: Logical Developments 2024.
Project:   ConNote Portal
Filename:  Config.js
Authors:    Dean B. Leggo, John D. Kohl
Version:   0.25
Description:
Organisational specific configuration and assets

History:
0.25  22-11-24 PWM   (ld0013147) Changed port number that we use to access Bishop's ConNote server
0.24  21-11-24 JDK   (LD0013075) Define URL for consignment tracking requests
0.23  14-08-24 JRB   (ld0012948) Increased max-items to 30
0.22  16-05-24 JRB   (ld0012760) Increased max-items from 10 to 20
0.21  11-03-24 JRB   (ld0012659) Added delivery schedule validation
0.20  07-03-24 JRB   (ld0012661) Added classes 7, 6.2 and 5.2
0.19  08-02-24 JRB   (ld0012403) Added consignment complete 
0.18  01-11-23 JRB   (ld0012318) midday is now based off of the booking datetime
0.18  27-10-23 JRB   (ld0012318) now is now defined as when the Booking was made.
0.17  24-10-23 JRB   (ld0012316) Commented out unused logic. Changed assignment of midday to new Date(pickupTime) instead of pickupTime.
0.16  06-10-23 JRB   (ld0012283) Added All dg Classes except 1, 5.2, 6.2 and 7 to Allowed DG classes.
0.15  12-07-23 JRB   (ld0012171) Added Max Freight Items
0.14  23-05-23 JRB   (ld0011870) Changed now < midnight to readyAt < midnight
0.13  19-05-23 JRB   (ld0012102) Added pickupComplete section to legal object
0.12  05-05-23 PWM   (ld0012078) New URL for connection to GBT's servers.
0.11  13-04-23 PWM   (ld0011487) Prepare for deployment, add live/demo site settings
0.10  16-03-23 DBL   (ld0011928) Change the max width and height for freightCardValidation
0.09  06-01-23 JRB   (ld0011855) Updated legal body "Loading and Unloading Allowances" to match desktop.
0.08  08-12-22 DBL   (ld0011809) double the request timeout
0.07  09-11-22 DBL   LD0011685 Fixed pickupTimeValidation as it was not displaying the correct message
0.06  08-11-22 DBL   LD0011685 Modified pickupTimeValidation
0.05  11-10-22 JRB   (ld0011681) Fixed Schedule Pickup Typo.
0.04  11-10-22 JRB   (ld0011673) Changed Terms and Conditions to the new correct link.
0.03  06-10-22 PWM   (ld0011661) Changed how Contact Us is rendered.  Create contactUs link
0.02  06-10-22 PWM   (ld0011663) Changed text based on input from GBT.
0.01  23-09-22 ADM   Fixed GST typo
0.00	23-02-22 DBL   Created.
----------------------------------- */

import { isDev, importAll } from "../Common/utils";

const sessionPrompt = 1000 * 30; // 30 seconds before the server timeout
const requestTimeout = 1000 * 120; // 2 minute timeout for HTTP requests

const theme = 'GBT'; // This must be the same as the name of the parent folder in /configurations, i.e. /configurations/LD = theme 'LD';

const baseURL = isDev()
  ? "http://localhost:5191/api/ConNote/" // development
//  : "https://mail.logicaldevelopments.com.au/cgi-bin/nph-omnisrestcgi/ws/192.168.86.92:5194/api/ConNote/"; // test on LD mail server
  : "https://connoteapi.bishopstransport.com.au/cgi-bin/omnisrestisapi.dll/ws/192.168.5.93:5193/api/ConNote/"; // Live on GBT servers

// const omnisUrl = isDev()
//   ? "http://localhost/omnisrest/ws/5191/api/ConNote/connoteportal" // development
// //  : "https://mail.logicaldevelopments.com.au/cgi-bin/nph-omnisrestcgi/ws/192.168.86.92:5194/api/ConNote/connoteportal"; // test on LD mail server
//   : "https://connoteapi.bishopstransport.com.au:8443/cgi-bin/omnisrestisapi.dll/ws/192.168.5.93:5193/api/ConNote/connoteportal";    // Live on GBT servers

const omnisUrl = baseURL + "connoteportal";

const trackingURL = baseURL + "connoteapi";

/*
 * Company details
 */

const companyName = "Bishop's Transport";
const dashboard = "Bishop's Transport Customer Portal"
const contactUs = 'https://bishopstransport.com.au/contact-the-friendly-team/';
const companyLogo = process.env.PUBLIC_URL + '/bishops-logo-350.png'; // also change the reference in public/index.html
const loginLogo = '';
const portalLogo = '';
const callError = "please call Sales on 08 9259 7538 to proceed"; // general error message
const fallbackPriceListWarning = <p>
  Please note that based on your from and to selections made above, you will be using the General rate card to generate this quote as your current rate card does not have rates for the above from and to route.<br/>
  If you would like to discuss adding these rates to your rate card, please contact Sales on 08 9259 7500.<br/>
  Thank you.
  </p>; // Warning message to display if the customer has a bespoke pricelist
const legal = {
  body: <>
    <p>Prices quoted subject to confirmation of weights and dimensions.</p>
    <h3>Insurance:</h3>
    <p>
      G Bishops Transport Services are not Common Carriers. Insurance (of your goods) is therefore NOT included in this freight quotation and
      will remain YOUR responsibility to arrange appropriate transit insurance at ALL times whilst in G Bishops Transport Services care.
    </p>
    <h3>Loading and Unloading Allowances</h3>
    <p>
      Bishops Transport allows one (1) hour loading time and one (1) hour unloading time for Full Loads or 30 minutes loading time and 30 minutes
      unloading time for less than full loads. Current demurrage per hour plus GST charges will apply.
    </p>
    <p>
      Sender and receiver to supply loading and unloading equipment. If not available, please advise when arranging quote.
    </p>
    <p>
      Quotes are valid for 14 days and may change due to monthly fuel levy adjustments.
    </p>
    <h3>Terms & Conditions</h3>
    <p>
      A full list of GBT Terms and Condititons are avaliable on our website: <br />
      <a target="_blank" rel="noreferrer" href="https://bishopstransport.com.au/wp-content/uploads/2020/08/2020-Conditions-of-Carriage-202008.pdf"> https://bishopstransport.com.au/wp-content/uploads/2020/08/2020-Conditions-of-Carriage-202008.pdf</a>
    </p>
  </>,
  quotation: <>
    <p>To accept the quote and agree to the GBT Terms &amp; Conditions, click the Accept Quote button.</p>
  </>,
  pickupComplete: <>
    <p>To cancel or amend this pickup, please call pickups on 9259 7500, dial extension 1.</p>
  </>,
  consignmentComplete: <>
    <p>To cancel or amend this consignment, please call our Operations Team on 9259 7500, dial extension 1.</p>
  </>
  };


/*
 * Page Validation
*/

const freightCardValidation = {
  maxTotalWeight: 8000, // kg
  maxTotalCubicVolume: 25, // cubic metres
  maxLength: 12, // metres
  maxWidth: 2.5, // metres
  maxHeight: 2.8, // metres
  maxItems: 30, // Max allowed number of freight items
  default: {
    maxTotalWeight: 1000*1000, // kg
    maxTotalCubicVolume: 1000*1000, // cubic metres
    maxLength: 1000, // metres
    maxWidth: 1000, // metres
    maxHeight: 1000, // metres
    maxItems: 0, // Max allowed number of freight items. (0 is no limit)
  }
};

const pickupValidation = {
    pickupTimeValidation: (customerClosingTime, readyTime, bookedAt) => {
    // customerClosingTime is required
    // readyTime is optional

    let msg = null; // message on success
    let error = null; // message on failure
    
    const bookingTime = bookedAt ? bookedAt : new Date() // Booking time is either now or when the booking was created.
    const cutOffTime = new Date(customerClosingTime.getTime() - (1000 * 60 * 60 * 4)); // subtract 4 hours from the closing time

    const pickupTime = readyTime ? new Date(readyTime) : new Date();
    pickupTime.setHours(11, 0, 0, 0); // 11:00 AM on the readyTime day

    // midnight tonight
    const midnight = new Date(bookingTime); // Ready Date based off the current/booked date
    midnight.setHours(0, 0, 0, 0);
    midnight.setDate(midnight.getDate() + 1)

    // midday on the pickup day
    const midday = new Date(pickupTime) // must use new Date(pickupTime) otherwise we are assigning a reference and changing midday will change pickupTime.
    midday.setHours(12, 1, 0, 0);

    if (readyTime) { // not ready now
      if (readyTime < midnight) { // ready at today
        if (bookingTime > pickupTime) // Current time > 11 am
          msg = 'Our standard pickup cut-off time is 11am. Due to this pickup being past the cut-off time, freight will be picked up on the next business day.'
        else { //Current time < 11 am
          if (readyTime > midday) // Ready at time >  12:01 pm
            msg = 'We require a 4 hour collection window between freight ready for collection and 4:00PM. Freight will be picked up on the next business day.'
          else if (cutOffTime < bookingTime) // Ready at time < 12:01 pm and closing time < 4hrs from current time
            msg = 'We require a 4 hour collection window between freight ready for collection and your business closing time. Freight will be picked up on the next business day.'
        }
      }
      else { // ready at on a future date
        if (readyTime > midday) // Ready at time > 12:01 pm
          msg = 'We require a 4 hour collection window between freight ready for collection and 4:00PM. Freight will be picked up on the next business day.'
        else if (cutOffTime < readyTime) // Ready at time < 12:01 pm and closing time < 4hrs from ready at time
          msg = 'We require a 4 hour collection window between freight ready for collection and your business closing time. Freight will be picked up on the next business day.'
      }
    }
    // -- Below "Ready now" logic is functionally pointless. We return the same message if we were to do this using the ready at time!
    // else { // ready now
    //   if (now > pickupTime) // Current time > 11 am
    //     msg = 'Our standard pickup cut-off time is 11am. Due to this pickup being past the cut-off time, freight will be picked up on the next business day.'
    //   else if (cutOffTime < now) // Current time < 11 and closing time < 4hrs from current time
    //     msg = 'We require a 4 hour collection window between the booking time and your business closing time. Freight will be picked up on the next business day.'
    // }

    return [ msg, error ];
  },
};

const deliveryValidation = {
  deliveryTimeValidation: (readyDate, rate) => {
    let msg = null; // message on success
    let error = null; // message on failure

    const now = new Date();

    const today = new Date()
    today.setHours(0,0,0,0)

    const freezerTime = new Date();
    freezerTime.setHours(11, 0, 0, 0);

    const generalTime = new Date();
    generalTime.setHours(13, 0, 0, 0);

    if (readyDate.getTime() === today.getTime()) {
      if (rate === 'Freezer/Chiller' && now > freezerTime) 
        error = 'Receivals for Freezer/ Chiller close at 11am, Please select another date'
      else if (now > generalTime) 
        error = 'Receivals for General Freight close at 1pm, Please select another date'
    }
    return [ msg, error ];
  }
};

const dgValidation = {
  images: importAll(require.context('../assets/DGLABELS', false, /\.(png|jpe?g|svg)$/)),
  classes: [
    {name: 'dgClass1', title: 'Class 1: Explosive Substances & Articles', sub: 'e.g. flares or detonators', icon: 'model1.png'},
    {name: 'dgClass2.1', title: 'Class 2.1: Flammable Gases', sub: 'e.g. aerosols or LNG', icon: 'model2.1a.png'},
    {name: 'dgClass2.2', title: 'Class 2.2: Non-flammable Non-Toxic Gases', sub: 'e.g. nitrogen or oxygen', icon: 'model2.2.png'},
    {name: 'dgClass2.3', title: 'Class 2.3: Toxic Gases', sub: 'e.g. ammonia or chlorine', icon: 'model2.3.png'},
    {name: 'dgClass3', title: 'Class 3: Flammable Liquids', sub: 'e.g. ethanol or petrol', icon: 'model3.png'},
    {name: 'dgClass4', title: 'Class 4: Flammable Solids', sub: 'e.g. sulfur or matches', icon: 'model4.1.png'},
    {name: 'dgClass4.2', title: 'Class 4.2: Spontaneous Combustible Substances', sub: 'e.g. phosphorus or sodium hydrosulphide', icon: 'model4.2.png'},
    {name: 'dgClass4.3', title: 'Class 4.3: Water Reactive Substances', sub: 'e.g. calcium carbide or aluminium phosphide', icon: 'model4.3.png'},
    {name: 'dgClass5.1', title: 'Class 5.1: Oxidising Substances', sub: 'e.g. fertiliser or calcium hypochlorite', icon: 'model5.1.png'},
    {name: 'dgClass5.2', title: 'Class 5.2: Organic Peroxides', sub: 'e.g. fibreglass resin or MEKP', icon: 'model5.2b.png'},
    {name: 'dgClass6.1', title: 'Class 6.1: Toxic Substances', sub: 'e.g. pesticides or formaldehyde', icon: 'model6.1.png'},
    {name: 'dgClass6.2', title: 'Class 6.2: Infectious Substances', sub: 'e.g. pathology specimens or clinical waste', icon: 'model6.2.png'},
    {name: 'dgClass7', title: 'Class 7: Radioactive Material', sub: 'e.g. smoke detectors or medical radiation sources', icon: 'model7d.png'},
    {name: 'dgClass8', title: 'Class 8: Corrosive Substances', sub: 'e.g. caustic soda or nitric acid', icon: 'model8.png'},
    {name: 'dgClass9', title: 'Class 9: Miscellaneous', sub: 'e.g. dry ice or asbestos', icon: 'model9.png'},
  ],
  allowed: ['dgClass2.1','dgClass2.2','dgClass2.3','dgClass3','dgClass4','dgClass4.2','dgClass4.3','dgClass5.1','dgClass5.2','dgClass6.1','dgClass6.2','dgClass7','dgClass8','dgClass9'], // only accept these dangerous goods classes
};

export {
  companyLogo, loginLogo, portalLogo, contactUs, companyName, dashboard, legal, omnisUrl, trackingURL, callError, fallbackPriceListWarning, dgValidation,
  freightCardValidation, pickupValidation, sessionPrompt, requestTimeout, deliveryValidation
 }