/* -----------------------------------
Copyright: Logical Developments 2025.
Project:   ConNote Portal
Filename:  DropFreight.js
Author:    John D. Kohl
Version:   0.15
Description:
Show consignment numbers and display selected consignments.

History:
0.15  02-04-25 JRB   LD0013312 Added customer default extra email
0.14  05-06-24 JRB   (ld0012770) Recreated based off QuoteConvert.
0.13  03-03-23 JRB   (ld0011931) Handle case of no state
0.12  25-11-22 DBL   (ld0011792) Go home and not back
0.11  23-11-22 JRB   Added quote to the loadStore
0.10  01-11-22 DBL   Handle all POST errors, rtPortal now reutrns an object and not an array.
0.09  30-08-22 JDK   Extracted navigation code to useEffect in order to only run on RESTful method success
0.08  04-07-22 JRB   Changed the saving when using next to specify not converting.
0.07  30-06-22 JRB   Added Consignment to reducer. Added an object.assign. Added compactStore and saving of dg items
0.06  21-06-22 JRB   Load the quote and add any existing dgInfo.
0.05  16-06-22 JRB   Added a check for the current navstep so that if we don't have dangerous goods then the step is skipped.
0.04  15-06-22 JRB   Added loadStore to reducer and dispatcher call when first loading data
0.03  13-06-22 JRB   Added pop and shift to options fetched when first fetched.
0.02  01-06-22 JRB   Added rest call to get quote details
0.01  03-05-22 JRB   Added session loading
0.00	11-04-22 JDK   Created.
----------------------------------- */

import React, { useState, useEffect, useReducer } from "react";
import { useLocation, useNavigate as useRouterNavigate } from 'react-router-dom';
import useNavigate from '../Navigation/useNavigate';
import useAuthenticate from '../Session/useAuthenticate';
import useRest from "../Session/useRest";
import { getStore } from '../Session/Storage';
import { mergeDestinations } from "../Common/utils";
import PageAccounts, * as Accounts from "../NewPickup/PageAccounts";             // from NewPickup
import PageDetails, * as Details from "../NewPickup/PageDetails";                // from NewPickup
import PageDG, * as DG from "../NewPickup/PageDG";                               // from NewPickup
import PageSchedule, * as Schedule from "../DirectDropFreight/PageSchedule";             // from NewPickup
import PageReview, * as Review from "../DirectDropFreight/PageReview";                   // from NewPickup
import PageFreight, * as Freight from "../ConvertQuote/PageFreight";
import PageInstructions, * as Instructions from "./PageInstructions";
import PageServices, * as Services from "../DirectDropFreight/PageServices";
import DropFreightConsignment, * as dfConsignment from "../DirectDropFreight/PageConsignmentNumber";
import PageConsignmentDownload, * as Consignment from '../DirectDropFreight/PageConsignmentDownload';
import { freightCardValidation as validation } from "../Configuration/Config";

const menu = [{name: 'Accounts', click: true},
              {name: 'Enter Consignment Number', click: false},
              {name: 'Details', click: false},
              {name: 'Check Freight', click: false},
              {name: 'Additional Services', click: false},
              {name: 'Dangerous Goods', click: false},
              {name: 'Schedule Drop In', click: false},
              {name: 'Special Instructions', click: false},
              {name: 'Review Dropfreight', click: false},
              {name: 'Create Consignment', click: false}];
              
const getOptions = () => {
  let options = getStore('options');
  return (options !== null && Object.keys(options).length) ? options : null;
}

class init {
  constructor(options) {
    let accounts = new Accounts.init();
    let details = new Details.init();
    let freight = new Freight.init();
    let services = new Services.init();
    let dg = new DG.init();
    let schedule = new Schedule.init(options ? options.customerDefaults.defaultOpen : null, options ? options.customerDefaults.defaultClose : null); // Schedule is the only field not enterable in a quote so only load this when coming from a quote.
    let instructions = new Instructions.init();
    let review = new Review.init();
    let consignment = new Consignment.init();
    let dfconsignment = new dfConsignment.init(options ? options.customerDefaults.defaultExtraEmail : null)
    return {...accounts, ...details, ...freight, ...services ,...dg, ...schedule, ...instructions, ...review, ...consignment, ...dfconsignment};
  }
}

function reducer(store, action) {
  // console.log('DropFreight Reducer: store, action'); console.log(store); console.log(action);

  // pass down the entry to update and the action, adding the updated entry into the store
  
  switch (action.page) {
    case 'Accounts':
      return {...store, ...Accounts.reducer(store, action)};
    case 'dfConsignment':
      return {...store, ...dfConsignment.reducer(store, action)};
    case 'Details':
      return {...store, ...Details.reducer(store, action)};
    case 'Freight':
      return {...store, ...Freight.reducer(store, action)};
    case 'Services':
      return {...store, ...Services.reducer(store, action)};
    case 'DG':
      return {...store, ...DG.reducer(store, action)};
    case 'Schedule':
      return {...store, ...Schedule.reducer(store, action)};
    case 'Instructions':
      return {...store, ...Instructions.reducer(store, action)};
    case 'Review':
      return {...store, ...Review.reducer(store, action)};
   // case 'loadStore':  Can not have a draft. No need for this.
      case 'reset':
      return new init(getOptions())
    
    default:
      throw new Error(`Drop Freight Page (${action.page}) is not supported`);
  }
}

function DropFreight() {
  const { navState, changeType, changeState } = useNavigate();
  const navigate = useRouterNavigate();
  const location = useLocation();
  const { loggedIn, customer, authModal } = useAuthenticate();
  const [ store, dispatcher ] = useReducer(reducer, new init(getOptions()));
  const [ fetchOptions, options, optionsError ] = useRest('GET', 'dropfreight');
  const [ myOptions, setMyOptions ] = useState({});
  // const [ storeLoaded, setStoreLoaded ] = useState(false); // can this join isLoaded
  const skipStep = false;
  // useEffect(() => { console.log('Store:'); console.log(store) }, [store]); // debugging the Store

  // on first load, set the navigation type and initial state
  useEffect(() => {
    if (loggedIn) {
      changeType('stepper', { stepArray: menu, stepCurrent: 1 });
      if (customer.permissions.Quotations.edit === true && customer.permissions.Consignments.add === true)
        if (location.state && location.state.hasOwnProperty('quote'))
          fetchOptions({quote: location.state.quote.quoteNumber});
        else
          permissionDenied('You do not have a quotation to convert');
      else
        permissionDenied('You do not have access to convert a quotation to a consignment');
    }
  }, [loggedIn]); // eslint-disable-line

  // when the options arrive, load the store
  useEffect(() => {
    if (options) {
      const newOptions = {
        ...options,
        suburbs: mergeDestinations(options.suburbs, options.depots)
      }

      setMyOptions(newOptions)
      dispatcher({page: 'Instructions', name: 'loadStore', value: location.state.quote});
      dispatcher({page: 'Details', name: 'loadAddresses', value: {
        sender: newOptions.suburbs.find(s => s.value === location.state.quote.sender),
        receiver: newOptions.suburbs.find(s => s.value === location.state.quote.receiver),
        depots: newOptions.depots
      }})
      dispatcher({page: 'Details', name: 'loadQuote', value: location.state.quote, options: newOptions})
      dispatcher({page: 'Freight', name: 'loadStore', value: location.state.quote, validation});
      dispatcher({page: 'DG', name: 'loadStore', value: location.state.quote});
      dispatcher({page: 'Review', name: 'loadStore', value: location.state.quote});
      dispatcher({page: 'dfConsignment', name: 'loadStore', value: location.state.quote});
      dispatcher({page: 'dfConsignment', name: 'ExtraEmail', value: getOptions().customerDefaults.defaultExtraEmail});
    }
  }, [options, location.state])

  // Issue loading the options, bail out. Most likely permission denied due to already being converted
  useEffect(() => {if (optionsError) permissionDenied(optionsError)}, [optionsError]) // eslint-disable-line

  const permissionDenied = (description) => {
    authModal({
      title: 'Permission Denied',
      description: description,
      yes: {name: 'Go Back', call: () => navigate('/')} // go home (ld0011792)
    });
  };

  // default previous click
  const previousClick = (enabled) => {
    if (enabled) {
      const goto = navState.stepCurrent - 1;
      const newMenu = navState.stepArray.map((item, index) =>
        index + 1 <= goto ? { ...item, click: true } : { ...item, click: false }
      );
      changeState({ ...navState, stepCurrent: goto, stepArray: newMenu });
    }
  };

  // default next click and enable the next menu item
  const nextClick = (enabled) => {
    if (enabled) {
      const goto = navState.stepCurrent + 1;
      const newMenu = navState.stepArray.map((item, index) =>
        index + 1 <= goto ? { ...item, click: true } : { ...item, click: false }
      );
      changeState({ ...navState, stepCurrent: goto, stepArray: newMenu });
    }
  }

  const skipNext = (enabled) => {
    if (enabled) {
      const goto = navState.stepCurrent + 2;
      const newMenu = navState.stepArray.map((item, index) =>
        index + 2 <= goto ? { ...item, click: true } : { ...item, click: false }
      );
      changeState({ ...navState, stepCurrent: goto, stepArray: newMenu });
    }
  }

  const skipPrev = (enabled) => {
    if (enabled) {
      const goto = navState.stepCurrent - 2;
      const newMenu = navState.stepArray.map((item, index) =>
        index - 2 <= goto ? { ...item, click: true } : { ...item, click: false }
      );
      changeState({ ...navState, stepCurrent: goto, stepArray: newMenu });
    }
  }

  // send all controls to each page and they can decide what to use
  let controls = {
    next: {call: nextClick, enable: false},
    previous: {call: previousClick, enable: true}
  };

  // Switch the card forms
  if (navState)
    switch (navState.stepCurrent) {
      case 1:
        return (
          <div className='hideOverflow-x'>
            <PageAccounts store={store} dispatcher={dispatcher} controls={{...controls, next: {call: (skipStep ? skipNext : nextClick), enable: false}}} options={myOptions} />
          </div>
        );
      case 2:
        return (
          <div className='hideOverflow-x'>
            <DropFreightConsignment store={store} dispatcher={dispatcher} controls={controls} options={myOptions} />
          </div>
        );
      case 3:
        return (
          <div className='hideOverflow-x'>
            <PageDetails store={store} dispatcher={dispatcher} controls={{...controls, previous: {call: (skipStep ? skipPrev : previousClick), enable: true}}} options={myOptions} />
          </div>
        );
      case 4:
        return (
          <div className='hideOverflow-x'>
            <PageFreight store={store} dispatcher={dispatcher} controls={controls} options={myOptions} validation={validation} />
          </div>
        );
      case 5:
        return (
          <div className='hideOverflow-x'>
            <PageServices store={store} dispatcher={dispatcher} controls={controls} options={myOptions} />
          </div>
        );
      case 6:
        return (
          <div className='hideOverflow-x'>
            <PageDG store={store} dispatcher={dispatcher} controls={controls} />
          </div>
        );
      case 7:
        return (
          <div className='hideOverflow-x'>
            <PageSchedule store={store} dispatcher={dispatcher} controls={controls} options={myOptions} />
          </div>
        );
      case 8:
        return (
          <div className='hideOverflow-x'>
            <PageInstructions store={store} dispatcher={dispatcher} controls={controls} />
          </div>
        );
      case 9:
        return (
          <div className='hideOverflow-x'>
            <PageReview store={store} dispatcher={dispatcher} controls={controls} options={myOptions} />
          </div>
        );
      case 10:
        return (
          <div className='hideOverflow-x'>
            <PageConsignmentDownload store={store} dispatcher={dispatcher} controls={controls} options={myOptions} />
          </div>
        );
      default: return null;
    }
  else
    return null;
}

export default DropFreight